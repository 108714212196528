<template>
  <!-- 左侧导航栏 -->
  <menu-wrapper active-index="">
    <div class="secondTitle">我的采购清单</div>
    <div class="purchase" >
      <div class="btn" v-if="state">
        <el-button type="primary" size="small" @click="addPurchase"
          >添加清单</el-button
        >
      </div>
      <div class="purchase-heade">
       
      </div>
      <div
        class="purchase-box"
        v-infinite-scroll="load"
        v-loading="loading"
        infinite-scroll-disabled="disabled"
      >
        <!-- 自定义滚动条 -->
        <scrollbar height="60vh" v-if="purchaseList.length > 0 || loading" ref="scrollRef">
          <!-- 采购清单描述列表 -->
          <div class="purchase-list">
            <el-descriptions
              class="purchase-descriptions"
              :column="2"
              border
              v-for="(item, index) in purchaseList"
              :key="index"
              :title="item.time.format('yyyy-MM-dd HH:mm:ss')"
              labelClassName="descriptions-label"
              contentClassName="descriptions-content"
            >
              <template slot="extra" v-if="item.isAdd">
                <el-button
                  type="primary"
                  size="small"
                  @click="saveClick(item)"
                  class="extra-btn"
                  >保存</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="purchaseList.remove(item)"
                  class="extra-btn"
                  >取消</el-button
                >
              </template>
              <el-descriptions-item :span="2">
                <template slot="label">
                  <!-- <i class="el-icon-goods"></i> -->
                  商品链接
                </template>
                <el-input
                  v-if="item.isAdd"
                  v-model="item.goodsName"
                  placeholder="请输入需要采购的商品链接"       
                ></el-input>
                <span v-else>
                  {{ item.goodsName }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">商品编码</template>
                <el-input
                  class="num"
                  v-if="item.isAdd"
                  v-model="item.goodsNo"
                  type="number"
                ></el-input>
                <span v-else>{{ item.goodsNo }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">商品数量</template>
                <el-input
                  v-if="item.isAdd"
                  v-model="item.goodsNum"
                  type="number"
                  step="1"
                  min="1"
                  max="99"
                  oninput="value=value.replace(/[^\d]/g,'');if(value>99)value=99"
                ></el-input>
                <span v-else>{{ item.goodsNum }}</span>
              </el-descriptions-item>
              <el-descriptions-item v-if="!item.isAdd">
                <template slot="label">
                  <!-- <i class="el-icon-link"></i> -->
                  云淘通编码
                </template>
                 <el-tooltip :disabled="!item.platformNo" class="item" effect="dark" content="点击跳转商品详情" placement="left">
                <a
                  :href="item.platformNo ?`#/goods/info?skuId=${item.platformNo}` :`javascript:void(0)`"
                  class="link"
                  :target="item.platformNo? '_blank':''"
                  >
                    <span>{{ item.platformNo ? item.platformNo : "暂无" }}</span>
                  </a>
                  </el-tooltip>
              </el-descriptions-item>
              <el-descriptions-item v-if="!item.isAdd">
                <template slot="label">上架状态</template>
                {{ item.state == 0 ? "未上架" : "已上架" }}
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <template slot="label">
                  备注
                </template>
                <el-input
                  v-if="item.isAdd"
                  type="textarea"
                  placeholder="抒善语，结善缘；弹指间，心无间。"
                  v-model="item.remark"
                  maxlength="500"
                  show-word-limit
                ></el-input>
                <el-tooltip class="item" effect="dark" content="点击查看备注详情" placement="left"  v-else>
                <div @click="remarkClick(item, index)" class="link">
                    <span v-if="item.remark">{{ item.remark }}</span>
                    <span v-else class="EmptyValue">空</span>
                </div>
                 </el-tooltip>
              </el-descriptions-item>
            </el-descriptions>
            <!-- <p v-if="loading">加载中...</p> -->
            <p v-if="noMore && itemCount / pageSize > 1">没有更多了</p>
          </div>
        </scrollbar>
        <el-empty v-else :description="description"></el-empty>
      </div>
    </div>
    <div>
      <!-- 留言全部内容弹窗 -->
      <el-dialog
        title="回复客服"
        :visible.sync="dialogVisible"
        width="60vw"
        center
      >
        <div class="remark-box">
          <scrollbar ref="scrollbarRef" height="calc(50vh - 148px)" :isTop="false" class="scroll-container">
            <div class="remark-list">
              <div
                :class="['remark', { right: item.type == 1 }]"
                v-for="(item, index) in remarkList"
                :key="index"
              >
                <div class="time">
                  {{ item.time.format("yyyy-MM-dd HH:mm:ss") }}
                </div>
                <div class="content">
                  <div class="user">{{ item.type == 0 ? "客服" : "我" }}</div>
                  <div class="txt">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
          </scrollbar>
          <div class="textarea">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="抒善语，结善缘；弹指间，心无间。"
              v-model="replyContent"
              maxlength="500"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="sendReplyContent"
            :disabled="replySendDisabled || !isReply"          
          >
            发 送
          </el-button>
        </span>
      </el-dialog>
    </div>
  </menu-wrapper>
</template>
<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
export default {
  components: {
    menuWrapper,
  },
  data() {
    return {
      dialogVisible: false, //是否弹窗
      remarkList: [], //留言列表
      replyContent: "", //留言回复内容
      replyType: 0, //是否可回复留言
      repleIndex: 0, //回复留言下标
      pageSize: 15, //每页条数
      pageIndex: 1, //页码
      itemCount: 0, //总记录数
      loading: false,
      replySendDisabled: false,
      purchaseList: [],
      state:true,
      description:'暂无数据'
    };
  },
  computed: {
    //用户是否可回复
    isReply() {
      return this.replyType == 1;
    },
    noMore() {
      return this.purchaseList.length >= this.itemCount;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    //验证字符是否为链接
    regexp(){
      return (val)=>{
        let chineseReg=/[\u4e00-\u9fa5]/;
        if(chineseReg.test(val))
        {
          return false;
        }
        let reg = /^(http|https):\/\/[\w]+(.[\w]+)([\w\-.,@?^=%&:/~+#\u4e00-\u9fa5]*[\w\-@?^=%&/~+#])/;  
        return reg.test(val);
      }
    }
  },
  watch: {
    state(e){
      if(!e){
        let msg="客服已关闭该功能，如需继续使用请您联系客服"
        this.notifyError(msg);
        this.description=msg
      }
    },
    // remarkList(e){
    //     if(this.$refs.scrollbarRef){
    //         this.$refs.scrollbarRef.updateScroll();
    //     }
    // }
  },
  mounted() {
    // *注：demo更新需要更新容器
    // if (this.$refs.scrollbarRef) {
    //   this.$nextTick(() => {
    //     this.$refs.scrollbarRef.resize();
    //   });
    // }
  },
  activated(){
    this.purchaseList=[]
    this.pageIndex=1
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.$axios
        .get("/api/UserPurchase/GetList", {
          params: {
            UserID: this.$store.getters.getUserId,
            PageSize: this.pageSize,
            PageIndex: this.pageIndex,
          },
        })
        .then((res) => {
          if (res) {
            this.itemCount = res.itemCount;
            if (res.data) {
              var list = res.data;
              console.log(res.data.state);
              if( typeof(res.data.state) !='undefined'){
                 this.state= res.data.state
              }
              for (let item of list) {
                let remark = "";
                if (item.ReplyList.length > 0) {
                  remark = item.ReplyList[item.ReplyList.length-1].ReplyContent;
                }
                let mode = {
                  goodsName: item.GoodsName,
                  goodsNo: item.GoodsNo,
                  goodsNum: item.GoodsNumber,
                  remark: remark,
                  platformNo: item.PlatformNo,
                  state: item.GoodsState,
                  replyType: item.ReplyState,
                  time: item.AddTime,
                  remarkList: item.ReplyList,
                  id: item.Id,
                };
                this.purchaseList.push(mode);
              }
            }
          }
          this.loading = false;
        }).catch(err=>{
           this.loading = false
           return Promise.reject(err);
        });
    },
    //下拉加载下一页
    load() {
      //防止重复加载
      if(this.noMore){
        this.pageIndex++;
        this.getList();
      }
    },
    //新增采购清单表
    addPurchase() {
      let mo = this.purchaseList.find((it) => it.isAdd);
      if (mo) {
        this.notifyError("存在未提交的清单，请提交完后在添加");
        return;
      }
      this.purchaseList.unshift({ isAdd: true, time: "" });
      // this.$nextTick(()=>{
      //      this.$refs.scrollRef.moveTop()
      // })
     
    },
    //提交采购清单
    saveClick(data) {
      if (data.goodsName && data.goodsNum) {
        if(!this.regexp(data.goodsName))
        {
          this.notifyError("商品链接填写错误，请检查确认后保存");
          return;
        }

        data.userId = this.$store.getters.getUserId;
        this.loading = true;
        this.$axios
          .post("/api/UserPurchase/Add", data)
          .then((res) => {
            if (res.data) {
              delete data.userId;
              this.purchaseList.remove(data);
              this.purchaseList.unshift({
                goodsName: data.goodsName,
                goodsNo: data.goodsNo,
                goodsNum: data.goodsNum,
                remark: data.remark,
                platformNo: "",
                state: 0,
                time: new Date(),
                replyType: 1,
                id: res.data,
                remarkList: [
                  {
                    ReplyType: 1,
                    ReplyContent: data.remark,
                    ReplyTime: new Date(),
                  },
                ],
              });
              delete data.isAdd;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.notifyError("商品链接或者商品数量未填写，请检查确认后保存");
      }
    },
    //点击采购清单留言内容
    remarkClick(val, index) {
      this.remarkList = [];
      this.dialogVisible = true;
      this.replyType = val.replyType;
      this.repleIndex = index;
      this.replyContent = "";
      let list = this.purchaseList[index].remarkList;
      for (const item of list) {
        this.remarkList.push({
          type: item.ReplyType,
          content: item.ReplyContent,
          time: item.ReplyTime,
        });
      }
      // this.$nextTick(()=>{
      //    this.$refs.scrollRef.moveBottom()
      // })
    },
    //发送留言
    sendReplyContent() {
      if (!this.replyContent) {
        this.notifyError("内容不能为空，请输入发送内容");
        return;
      }
      this.replySendDisabled = true;
      let purchase = this.purchaseList[this.repleIndex];
      const data = {
        ReplyId: purchase.id,
        ReplyContent: this.replyContent,
      };
      this.$axios
        .post("/api/UserPurchase/AddReply", data)
        .then((res) => {
          if (res.data) {
            purchase.remark = this.replyContent;
            data.ReplyType=1
            data.ReplyTime=new Date()
            purchase.remarkList.push(data)
            this.remarkList.push({
              type: 1,
              content: this.replyContent,
              time: new Date(),
            });
            this.replyContent = "";
          }
          this.replySendDisabled = false;
        })
        .catch((err) => {
          this.replySendDisabled = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  /* 去除数字输入框右侧按钮*/
  ::v-deep .num input::-webkit-outer-spin-button,
  ::v-deep .num input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep .num input[type="number"] {
    -moz-appearance: textfield;
  }
  /* 去除输入框的边框*/
  ::v-deep .el-input__inner {
    border: none;
  }
  /* 设置描述列表头部标题字体样式*/
  ::v-deep .el-descriptions__title {
    font-size: 14px;
    font-weight: inherit;
  }
  ::v-deep .el-descriptions__header{
    padding-top:10px;
    margin-bottom:10px;
  }
  .purchase {
    .btn {
      text-align: center;
    }
    .purchase-heade {
      // border-bottom: 1px solid $border-color;
      height: 50px;
      .purchase-heade-title {
        width: 100px;
        float: left;
        margin-top: 20px;
        font-weight: bold;
      }
    }
    .purchase-box {
      // border: 1px solid $border-color;
      // padding: 10px;
      p {
        text-align: center;
      }
      .purchase-list {
        //   max-height: 60vh;
        //   overflow: auto;
        padding-right: 20px;
        .purchase-descriptions {
          margin: 20px 0px;
          ::v-deep .descriptions-label {
            width: 100px;
            text-align: center ;
          }
          &:hover {
            z-index: 2;
            -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
            -webkit-transform: translate3d(0, -2px, 0);
            transform: translate3d(0, -2px, 0);
            .link {
              color: #66b1ff;
            }
          }
          .extra-btn {
            padding: 10px;
          }
          .link {
            font-size: 14px;
            color: #333;
            cursor: pointer;
            display: block;
          }
        }
      }
    }
  }
  .remark-box {
    .remark-list {
      line-height: 30px;
      margin-bottom: 20px;
      font-size: 16px;
      //   height: 50vh;
      //   overflow: auto;
      padding: 10px;
      .remark {
        &.right {
          .content {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .txt{
              background-color: #9eea6a;
            }
          }
        }

        .time {
          text-align: center;
          color: #909399;
          font-size: 14px;
        }
        .content {
          .user {
            margin: 5px 10px;
            color: #909399;
            font-size: 14px;
          }
          .txt {
            background-color: #e6e6e6;
            display: inline-block;
            border-radius: 10px;
            max-width: 61%;
            padding: 5px 10px;
            white-space: normal;
            word-break: break-all;
            margin-top: 3px;
            font-size: 14px;
          }
        }
      }
    }
    .textarea {
      padding-top: 10px;
    }
  }
  .EmptyValue
  {
    color: gray;
  }
  .el-button--primary{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
.el-button--primary:hover{
  opacity: 0.8;
}
</style>

